import React, { useState, } from "react"
import { graphql, Link } from "gatsby"
import CTAInlink from "../components/CTAInLink"
import Layout from "../components/layout"
import Form from "../components/form"
import Img from "gatsby-image"
import ValidateEmail, {  handleFormChange } from "../utils/form"
import FirstStepForm from "../components/firststepform"
import SEO from "../components/seo"



const links = {
  admissionForm:
    "https://drive.google.com/file/d/11PbZ3tpteOvFjQE17cJpfYfQmgUp-jVL/view",
  sampleEntranceQuestions: {
    1: "https://drive.google.com/file/d/1c51MFByyF_8Rqv0lHmEoEv2Hf4x0E6Ql/view",
    2: "https://drive.google.com/file/d/116B6TsGwAfm5Qjm3Yh0dmwmBOu9O0dJT/view",
    3: "https://drive.google.com/file/d/1dX5WF4643-4cGxiio4oZRc6ZLQu7YEuL/view",
    4: "https://drive.google.com/file/d/1kAZsxRRlglHgmOt433SqGo5n0eEyKQi3/view",
    5: "https://drive.google.com/file/d/1lXDXLTVl5XCq7amBNh7k3BZ11H3_rINA/view",
    6: "https://drive.google.com/file/d/1G0ilmmqzleqRqKpUGsSFISyViD8cC6Yy/view",
    7: "https://drive.google.com/file/d/1XobaNDmN1DO8hw8GnSWTr4x96ZL2flnJ/view",
    8: "https://drive.google.com/file/d/15qH4bRUmfWGzFKSuHKTtgoTtC1Qb-29w/view",
  },
}

const CircularPhotoCollection = ({ queryData }) => {
  return (
    <div className="-mt-12">
      <Img
        className=""
        fluid={queryData.bloomie.childImageSharp.fluid}
        alt="Hexagonal Photo Collection of Students"
        draggable={false}
      />
    </div>
  )
}

export const data = graphql`
  query {
    admissionData: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      isAdmissionOpen
      admissionGrades
      _id
    }
    firstImage: file(relativePath: { eq: "pooja.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    twoGirls: file(relativePath: { eq: "two-girls.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bloomie: file(relativePath: { eq: "bloomie.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bloomieSmall: file(relativePath: { eq: "bloomie-small.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    footballKick: file(relativePath: { eq: "kickstart.PNG" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const DownloadSampleQuestions = () => {
  const [formFields, setFormFields] = useState({
    grade: "",
    email: "",
  })

  const allGrades = [
    "PG",
    "Nursery",
    "LKG",
    "UKG",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
  ]

  const dataValidations = {
    email: ValidateEmail,
    grade: function (gradeValue) {
      return gradeValue !== "Select"
    },
  }

  function handleChange(e) {
    handleFormChange(e, setFormFields, formFields)

    // If the target field has met validation rule,
    // remove invalidation texts
    if (dataValidations[e.target.name](e.target.value)) {
      document.querySelector(`.${e.target.name}.error`).style.display = "none"
    }
  }

  function handleSubmit() {
    const grade = formFields.grade
    const ResponseMessage = () => {
      return (
        <div className="text-xl mt-4">
          {grade === "PG" ||
          grade === "Nursery" ||
          grade === "LKG" ||
          grade === "UKG" ? (
            <span>
              {grade} grade is available in our Itahari campus only yet, and we
              don't take entrace exam for determining admission in {grade}. Here
              is how you can
              <Link to="/contact" className="in-link">
                contact us
              </Link>{" "}
              for further information on admission.
            </span>
          ) : parseInt(grade) <= 8 && parseInt(grade) >= 1 ? (
            <span>
              {" "}
              Thank you for downloading the sample entrance questions. We wish
              you the best of luck for your exam.
            </span>
          ) : grade === "9" ? (
            <span>
              We do take admission for grade 9, but don't take entrance exam for
              it. Please
              <Link to="/contact" className="in-link">
                contact us
              </Link>{" "}
              directly if you are seeking for admission in grade 9.
            </span>
          ) : (
            <span>Sorry, we don't take admission for grade 10.</span>
          )}
        </div>
      )
    }
    let actionTo
    if (parseInt(grade) <= 8 && parseInt(grade) >= 1) {
      actionTo = links.sampleEntranceQuestions[grade]
    }

    return {
      responseToForm: <ResponseMessage />,
      actionTo: actionTo,
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      submitToNetlify={false}
      dataValidations={dataValidations}
      formValues={{
        ...formFields,
        "form-name": "Sample Entrance Questions Downloaded",
      }}
      formName="Sample Entrance Questions Downloaded"
    >
      <h3 className="section-head">Download Sample Entrance Questions</h3>
      <div className="">
        <input
          type="hidden"
          name="form-name"
          value="Sample Entrance Questions Downloaded"
        />
        <div className="form-field">
          <label htmlFor="sampleQuestionsGrade" className="block">
            Grade
          </label>
          <select
            name="grade"
            id="sampleQuestionsGrade"
            required
            value={formFields.grade}
            onChange={handleChange}
          >
            <option>Select</option>
            {allGrades.map(grade => (
              <option key={grade}> {grade}</option>
            ))}
          </select>
          <div className="error text-sm grade  text-red-700 hidden">
            Please select a grade
          </div>
        </div>
        <div className="form-field">
          <label htmlFor="sampleQuestionEmail" className="block">
            Email
          </label>
          <input
            type="email"
            value={formFields.email}
            onChange={handleChange}
            name="email"
            id="sampleQuestionEmail"
            placeholder="xyz@bloomn.edu.np"
          />
          <div className="error text-sm email  text-red-700 hidden">
            Sorry, we couldn't validate the email
          </div>
        </div>
      </div>
    </Form>
  )
}

export default function (props) {
  const queryData = props.data

  // if (queryData.admissionData === null) {
  //   takingAdmission = false
  //   admissionGrades = "1-9"
  // } else {
  //   takingAdmission = queryData.admissionData.isAdmissionOpen
  //   admissionGrades = queryData.admissionData.admissionGrades
  // }
  const admissionProcess = [
    `We usually take admission for students from grades 1 to 8 in Lalitpur campus and PG to 8 in Itahari Campus. If you are going to be in any of those grades in the upcoming school year, fill out the admission form below!`,
    `We meet online or at Bloom Nepal School for your Entrance Exam. (don’t be nervous, we’ve got sample questions below :)`,
    `We review your Exam (this is pretty quick; usually < 24 hrs), and set a meeting with your parent and you and consider your admission based on the entrance exam, extra-curricular involvement, and interpersonal qualities.`,
  ]
  return (
    <Layout style={{ width: "500px" }}>
    <SEO title="Admissions"/>

      <section className="pb-16 lg:border-r lg:border-b md:border-gray-600 md:py-16">
        <div className="section-for-small-devices lg:flex">
          <div className="lg:w-1/2 lg:pr-8 mt-8">
            <h2 className="section-head md:text-6xl text-3xl leading-none text-left">
              {" "}
              Be a{" "}
              <span className="md:text-6xl text-3xl text-red-800">Bloom </span>
              Nepal Student
            </h2>

            <p className="mt-4 md:mt-8">
              Be a part of the school that's futuristic, teaches empathy, and
              provides an environment for fostering each child's special area of
              interest.
            </p>
          </div>
          <div className="hidden lg:block lg:w-1/2 lg:pl-8">
            <CircularPhotoCollection queryData={queryData} />
          </div>
        </div>
      </section>
      <section className="lg:border-l lg:border-b border-gray-600">
        <div className="section-for-small-devices">
          <h1 className="section-head">
            <span className="text-blue-800 md:text-gray-800">Admission</span>{" "}
            Process
          </h1>
          <ol className="mt-4 list-outside pl-5 md:text-gray-900 md:mt-8">
            {admissionProcess.map(item => (
              <li className="mt-2 list-disc" key={item}>
                {item}
              </li>
            ))}
            <li className="font-medium mt-2 list-disc">
              You're a Bloom Nepal student now. <span>Yayy!</span>
            </li>
          </ol>
        </div>
      </section>
      <section className="lg:border-r lg:border-b border-gray-600 ">
        <div className="section-for-small-devices lg:flex items-center">
          <div className="md:w-1/2 lg:pr-8  hidden lg:block">
            <Img
              className="mt-4"
              fluid={queryData.twoGirls.childImageSharp.fluid}
              alt="two girl students posing for a photo"
              draggable={false}
            />
            {/* <p className="caption">Bloomies</p> */}
          </div>
          <div className="lg:w-1/2 lg:pl-8">
            <h2 className="section-head">Scholarship</h2>
            <div className="mt-4 lg:hidden section-image-fix">
              <Img
                className="mt-4"
                fluid={queryData.twoGirls.childImageSharp.fluid}
                alt="two girl students posing for a photo"
                draggable={false}
              />
              {/* <p className="caption">Bloomies</p> */}
            </div>
            <p className="mt-4 md:mt-6">
              We know that education will have the most impact on the lives of
              those who cannot afford to pay for it. To address this paradox, we
              set up
              <strong className="font-medium external-link">
                {" "}
                <a href="https://bloomnf.org/" className="in-link">
                  Bloom Nepal Foundation
                </a>
              </strong>{" "}
              that provides scholarships to those families.
            </p>
            <div className="lg:mx-auto lg:flex lg:justify-start lg:mt-4 mt-12">
              <CTAInlink
                linkto="/scholarship"
                text="learn more"
                className="bg-blue-800 hover:bg-blue-900 "
              />
            </div>
          </div>
        </div>
      </section>
      <section
        className="lg:border-l lg:border-b border-gray-600"
        id="entrance"
      >
        <div className="section-for-small-devices">
          <h2 className="section-head">
            <span className="text-red-800 md:text-gray-800">Entrance</span>{" "}
            Details
          </h2>
          <ul className="mt-4 list-disc pl-4 md:text-gray-800 md:mt-8">
            <li>The examination may be conducted online/in-person.</li>
            <li>
              You need to take the examination yourself; any help by third
              person isn't allowed.
            </li>
            <li>
              Subjects covered in exam vary by grade. See sample questions
              below.
            </li>
            <li>
              Entrace exam is just one part of the holistic application process.
              It is a major by not the only determining factor for admission.
            </li>
            <li>Come prepared, but don’t be nervous.</li>
            <li>Make sure you practice the sample questions.</li>
          </ul>
          <DownloadSampleQuestions />
        </div>
      </section>
      <section className="lg:border-r lg:border-b border-gray-600">
        <div className="section-for-small-devices">
          <div className="lg:w-4/5">
            <h3 className="section-head">
              KickStart your Journey to{" "}
              <span className="text-red-800">Bloom</span>
            </h3>

            <div className="mt-4">
              <FirstStepForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}