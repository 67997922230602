import React, { useState, useEffect } from "react"
import Form from "../components/form"
import ValidateEmail, { ValidatePhone, handleFormChange } from "../utils/form"
import { Link } from "gatsby"

const admissionFormLink =
  "https://drive.google.com/file/d/11PbZ3tpteOvFjQE17cJpfYfQmgUp-jVL/view"

// Function that returns which promise is resolve first.
const timeout = (prom, time) => {
  let timer
  return Promise.race([
    prom,
    new Promise((_r, rej) => (timer = setTimeout(rej, time))),
  ]).finally(() => clearTimeout(timer))
}

// Load Admission Data
async function loadAdmissionDataWithTimeOut(
  setLoadingAdmissionInfo,
  setLoadingFailed,
  setAdmissionGrades,
  setTakingAdmission
) {
  const dataFetchPromise = fetch(
    "https://hdf6d0e0.api.sanity.io/v1/data/query/production?query=*%5B_type%3D%3D'siteSettings'%5D%7BadmissionGrades%2C%20isAdmissionOpen%7D"
  )
  try {
    const result = await timeout(dataFetchPromise, 5000)
    const response = await result.json()
    const resultGist = response.result
    setLoadingAdmissionInfo(false)
    setLoadingFailed(false)
    setAdmissionGrades(resultGist[0].admissionGrades)
    setTakingAdmission(resultGist[0].isAdmissionOpen)
  } catch (e) {
    // / error or timeout?
    setLoadingFailed(true)
    setLoadingAdmissionInfo(false)
  }
}

export default function FirstStepForm() {
  const [loadingAdmissionInfo, setLoadingAdmissionInfo] = useState(true)
  const [admissionGrades, setAdmissionGrades] = useState()
  const [takingAdmission, setTakingAdmission] = useState()
  const [loadingFailed, setLoadingFailed] = useState(true)
  const [formFields, setFormFields] = useState({
    email: "",
    mobileNumber: "",
  })

  const dataValidations = {
    email: ValidateEmail,
    mobileNumber: ValidatePhone,
  }

  function handleChange(e) {
    handleFormChange(e, setFormFields, formFields)

    // If the target field has met validation rule,
    // remove invalidation texts
    if (dataValidations[e.target.name](e.target.value)) {
      document.querySelector(`.${e.target.name}.error`).style.display = "none"
    }
  }

  function handleSubmit() {
    // set the respones message
    const ResponseMessage = () => {
      return (
        <div className="text-xl mt-4">
          {takingAdmission ? (
            <span>
              Thank you for downaloding our admission form, we look forward to
              hearing from you soon. Please{" "}
              <Link to="/contact" className="in-link">
                contact us{" "}
              </Link>{" "}
              if you need any help filling the form.{" "}
            </span>
          ) : (
            <span>
              {" "}
              Thank you for your interest in Bloom. We will contact you soon. In
              the meantime, you are always welcome to visit us. Please go to our{" "}
              <Link to="/contact" className="in-link">
                contact page{" "}
              </Link>{" "}
              to arrange a school visit.{" "}
            </span>
          )}
        </div>
      )
    }
    let actionTo = null
    if (takingAdmission) {
      // link to admission form if admission is open
      actionTo = admissionFormLink
    }
    return {
      responseToForm: <ResponseMessage />,
      actionTo: actionTo,
    }
  }

  useEffect(() => {
    loadAdmissionDataWithTimeOut(
      setLoadingAdmissionInfo,
      setLoadingFailed,
      setAdmissionGrades,
      setTakingAdmission
    )
    // return () => {}
  }, [])

  return (
    <div>
      {/* Display Loading Admission Message If Loading */}
      {loadingAdmissionInfo ? (
        <div className="bg-red-700 px-2 py-1 text-white cursor-wait">
          <svg
            className="animate-spin h-5 w-5 mr-3 inline-block"
            viewBox="0 0 24 24"
          >
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Loading Admission Status
        </div>
      ) : loadingFailed ? (
        <div className="bg-red-700 px-2 py-1 text-white cursor-default">
          Sorry, we couldn't load the current admission status. However, please
          fill the form below and we will contact you.
        </div>
      ) : (
        <div>
          {/* When the form hasn't yet been submitted, and the loading is completed, display the admission status */}
          {!loadingFailed && !loadingAdmissionInfo ? (
            <div
              className={`${
                takingAdmission ? "bg-green-600" : "bg-red-800"
              }  text-sm text-white px-2 md:px-4 my-6`}
            >
              {takingAdmission ? (
                <span>
                  We’re currently taking admission for grades {admissionGrades}{" "}
                  for limited seats! Please fill the form below to download the
                  admission form.
                </span>
              ) : (
                <span>
                  Sorry, we aren’t taking any admission currently. Please fill
                  the form below and we will contact you when admission reopens.{" "}
                </span>
              )}
            </div>
          ) : null}
        </div>
      )}
      {/* /Display the form to download admission form or to fill out so that we can contact them  */}
      {/* submit to netlify only when not taking admission  */}
      <Form
        onSubmit={handleSubmit}
        submitToNetlify={takingAdmission === false}
        dataValidations={dataValidations}
        formValues={{ ...formFields, "form-name": "First Step Admission" }}
        formName="First Step Admission"
      >
        <div className="">
          <input type="hidden" name="form-name" value="First Step Admission" />
          <div className="form-field">
            <label htmlFor="mobileNumberFirstStep" className="block">
              Mobile Number
            </label>
            <input
              type="text"
              id="mobileNumberFirstStep"
              onChange={handleChange}
              value={formFields.mobileNumber}
              name="mobileNumber"
              placeholder="Eg: 9812345678"
            />
            <div className="error text-sm mobileNumber text-red-700 hidden">
              Please enter a valid phone number{" "}
            </div>
          </div>
          <div className="form-field">
            <label htmlFor="emailFirstStep" className="block">
              Email
            </label>
            <input
              type="email"
              id="emailFirstStep"
              value={formFields.email}
              onChange={handleChange}
              name="email"
              placeholder="Eg: xyz@bloom.edu.np"
            />
            <div className="error text-sm email text-red-700 hidden">
              Sorry, we could not validate your email
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}
